import React, { useMemo } from 'react'
import { I18nHtmlProps } from './types'
import { DSIHtml } from '../dsi_html'
import type { TranslateOptions } from 'i18n-js'
import { t } from 'shared'
// Quick fix for https://betterplace.atlassian.net/browse/DEV-23282
const escapeHTML = (value: string): string =>
  value.replace('&', '&amp;').replace('"', '&quot;').replace("'", '&#39;').replace('<', '&lt;').replace('>', '&gt;')

export const I18nHtml = ({ i18nKey, options: options_, ...props }: I18nHtmlProps) => {
  const options = useMemo(
    () =>
      options_ &&
      Object.keys(options_).reduce((sum, key) => {
        const value_ = options_[key]
        if (typeof value_ === 'number') {
          sum[key] = value_
        } else if (Array.isArray(value_)) {
          sum[key] = value_.map((val) => {
            return Object.keys(val).reduce((acc, curr) => {
              acc = { ...acc, [curr]: escapeHTML(String(val[curr])) }
              return acc
            }, {})
          })
        } else {
          const value = escapeHTML(String(value_))
          sum[key] = value
        }
        return sum
      }, {} as TranslateOptions),
    [options_]
  )
  const children = useMemo(() => t(i18nKey, options), [i18nKey, options])
  return <DSIHtml {...props}>{children}</DSIHtml>
}
