/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Honeybadger from '@honeybadger-io/js'
import SupportedBrowsersRegexp from 'supportedBrowsers'

type Badger = typeof Honeybadger
const noop = (..._args: any[]): any => {}

const HoneybadgerStub = {
  beforeNotify: noop,
  configure: noop,
  errorHandler: noop,
  requestHandler: noop,
  lambdaHandler: noop,
  config: {} as any,
  afterNotify: noop,
  notify: (...args) => {
    console.log('Honeybadger JS is stubbed in this env, skipping notice:', ...args)
  },
} as Badger

const IgnoredUserAgents = [
  'BingPreview',
  'Chrome[ /]([123]\\d|4[^1])\\.', // ancient chrome versions, except Googlebot (v41)
  'CriOS[ /][1234]\\d\\.', // ancient chrome-on-iOS versions
  'DuckDuckGo',
  'Firefox/[1234]\\d?\\.',
  'FxiOS/\\d\\.', // ancient Firefox for iOS (v < 10)
  'GSA',
  'hellotalk',
  'wkhtmltopdf',
  '(iPhone|iPad|CPU).*OS ([56789]|10)[._].*(Safari|like Mac OS)', // old mobile safari
  'MAGIX BROWSER',
  'Media Center PC', // windows media center browser (old IE)
  'MicroMessenger', // WeChat
  'MIUI',
  'Mozilla/5.*Android [1-4]\\..*AppleWebKit', // old stock android browser
  'MQQBrowser',
  'MSIE',
  'Opera Coast',
  'Opera Mini',
  'OPR[ /][1-5]\\d\\.', // old Operas (pre 2019)
  'Qt/',
  'QuantBrowser',
  'SamsungBrowser.[1-7]\\.', // old Samsung Internet browser (pre 2019)
  'Version/(\\d|10|11)\\.[\\d.]* Safari', // old Safaris (v < 12, pre 2018)
  'Yandex',
  'Trident/', // IE
  'UCBrowser',
  'Vivaldi',
  'WorxWeb',
  'Instagram',
]

/*
 About ignoring paypal errors:
 some error messages received by our code come from internal errors in paypal,
 these are generally generated when the user is interacting with paypal's own
 interface, so we should not handle them nor let them influence our own interface.
 We should instead expect paypal to handle them itself, and this includes
 retrying where needed.
*/
const IgnoredMessages = [
  '__firefox__', // internal error in Firefox for iOS
  'BetterJsPop',
  'Blocked a frame with origin',
  'Can not open popup window',
  'ChunkLoadError', // #168147439
  'Detected popup close', // happens when user closes the paypal popup
  'invalid_authenticity_token', // expected error thrown by ourselves
  'Error loading script',
  'Failed to execute.*Node', // caused by google translate et al.
  'GetCheckoutDetails failed', // paypal-internal error
  'GetCheckoutDetails has timed out', // paypal-internal error
  'IE Intranet mode',
  'Illegal invocation',
  'Loading.*chunk', // #168147439, #172605011
  'No value passed to payment',
  'Object prototype may only be an Object or null',
  'URIError.*recaptcha|recaptcha.*is not accessible',
  'Window closed',
  'Window not found on which to reject sendMessage',
  'Zugriff verweigert',
  'a[b].target.className.indexOf',
  'avast',
  'can not open popup window',
  'cannot redefine.*googletag',
  'closingEls',
  'could not retrieve data from the specified Element',
  'kaspersky',
  'network error',
  'no ack for postmessage',
  'non-configurable property .*userAgent',
  'pktAnnotationHighlighter',
  'postMessage zoid_init',
  'postrobot',
  'tgetT is not defined',
  'this.emitter.emit', // #176559712
  'track.uc.cn',
  'translate.googleusercontent.com',
  'webkitExitFullScreen',
  'window.*closed',
  'xoplatform/logger',
  'ztePageScrollModule',
  'Permission denied to access property',
  'moz-extension',
]

const IgnoredReferrers = [
  'search.naver.com', // Korean google alternative, yields "undefined is not a function"
]

const IgnoredUserAgentsRegexp = new RegExp(IgnoredUserAgents.join('|'), 'i')
const IgnoredMessagesRegexp = new RegExp(IgnoredMessages.join('|'), 'i')
const IgnoredStackRegexp = new RegExp(/(chrome|moz|safari)-extension|recaptcha/i)
const IgnoredReferrersRegexp = new RegExp(IgnoredReferrers.join('|'))

const reportingEnabled = process.env.HONEYBADGER_API_KEY && process.env.HONEYBADGER_ENV

if (reportingEnabled && Honeybadger) {
  Honeybadger.configure({
    apiKey: process.env.HONEYBADGER_API_KEY,
    environment: process.env.HONEYBADGER_ENV,
    revision: process.env.SEMAPHORE_GIT_SHA || process.env.CONTAINER_VERSION,
  })

  Honeybadger.beforeNotify((notice) => {
    if (!notice) return false
    if (!SupportedBrowsersRegexp.test(navigator?.userAgent)) return false
    if (IgnoredUserAgentsRegexp.test(navigator?.userAgent)) return false
    if (IgnoredStackRegexp.test(notice.stack)) return false
    if (IgnoredStackRegexp.test(notice.backtrace?.join(''))) return false
    if (IgnoredMessagesRegexp.test(notice.message)) return false
    if (IgnoredReferrersRegexp.test(document && document.referrer)) return false

    if (!/betterplace|rops|42/.test(window.location.hostname)) {
      console.log('stopped error reporting b/c of hostname')
      return false
    }
  })
}

export default (reportingEnabled && Honeybadger) || HoneybadgerStub
