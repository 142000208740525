import { Location as HistoryLocation } from 'history'
import { QueryHelper, Routes } from 'shared'
const pathRegex = /^\/[^/].*$/

interface HistoryLocationP extends HistoryLocation {
  state: {
    prevLocation: string
  }
}

export const isPath = (string: string) => string.match(pathRegex)

export class URLHelper {
  static get signUpPath() {
    return Routes.signUpPath({ return_to: window.location.pathname })
  }

  // If `prevLocation` is known, because it went through a ProtectedRoute
  // component, redirect back to this location after sign in / sign up.
  // Else if a valid `return_to` param was given, use it.
  // Else redirect to /manage/campaign.
  static returnToPath(location: Location | HistoryLocation) {
    const { prevLocation } = (location as HistoryLocationP).state || {}
    if (prevLocation) return prevLocation

    const query = QueryHelper.parseQuery(location)
    const returnTo = decodeURIComponent((query.return_to as string) || '')
    if (returnTo.match(/^\/(?!sign)[^/]/)) return returnTo

    return Routes.manageCampaignsPath()
  }
}
