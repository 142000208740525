/* eslint-disable @typescript-eslint/no-explicit-any */
import { Location as HistoryLocation } from 'history'
import QueryString from 'query-string'

interface HistoryLocationP extends HistoryLocation {
  state: {
    prevLocation: string
  }
}
export abstract class QueryHelper {
  static parseQuery = (location: Location | HistoryLocation = window.location) => QueryString.parse(location.search)

  static toQuery = (object: any) => QueryString.stringify(object)

  static slice(...params: any[]) {
    const query = this.parseQuery()
    const newQuery = params.reduce((acc, el) => ((acc[el] = query[el]), acc), {})
    return this.toQuery(newQuery)
  }
}
