import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import fetch from 'isomorphic-unfetch'
import { Routes, URLHelper } from 'shared'

const csrfMeta = document.querySelector('meta[name=csrf-token]')

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === 'error_404') {
        // Here we're using the native js redirection and not the react router one
        // because this is not a react component nor a hook. Thanks React
        window.location.replace(Routes.notFoundPath())
      }
      if (message === 'invalid_authenticity_token')
        window.alert(
          'Ein technisches Problem ist aufgetreten. Um diese Seite zu verwenden, muss dein Browser zumindest technisch notwendige Cookies akzeptieren.'
        )
      if (message === 'undefined') window.location.reload()
      if (message === 'missing_session') window.location.href = URLHelper.signUpPath
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    })
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
    // leave it to the browser to reload the page when a connection is established
    // TODO: maybe render a nice, facebook-like "you're offline" page instead?
    window.location.reload()
  }
})

// this fires only on network errors.
// it passes control on to errorLink after all retries failed.
const retryLink = new RetryLink({ delay: { initial: 500 }, attempts: { max: 8 } })

const httpLink = createHttpLink({
  credentials: 'same-origin',
  headers: { 'X-CSRF-Token': csrfMeta ? csrfMeta.getAttribute('content') || '' : '' },
  fetch,
})

export const client = new ApolloClient({
  link: errorLink.concat(retryLink).concat(httpLink),
  cache: new InMemoryCache(),
})
