/* eslint-disable @typescript-eslint/no-empty-function */

import { Maybe } from 'gql'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const noop = (...args: any[]) => {}
export const sum = (...args: number[]) => args.reduce((sum, v) => (sum += v), 0)
export const oneOf = <T>(test: (v: T) => boolean, ...args: Array<T>) => {
  let res = false
  for (const a of args) {
    if (test(a)) {
      res = true
      break
    }
  }
  return res
}

export const allOf = <T>(test: (v: T) => boolean, ...args: Array<T>) => {
  return oneOf((v: T) => !test(v), ...args)
}

export const isTruthy = <T>(ele: T) => !!ele
export const isFalsy = <T>(ele: T) => !ele

export const filterOutFalsyFromArr = <T>(arr?: Maybe<T[]>) =>
  ((arr && arr.filter(isTruthy)) || []) as Array<Exclude<T, false | undefined | null | '' | 0>>

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const asTuple = <T>(arr: Array<T>) => [arr[0]!] as [T]
